import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);

jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	$('#main-menu > .menu-item > a').addClass('js-accordion-trigger');
	$('#main-menu > .menu-item .accordion__content--wrapper').css('display', 'none'); // All items closed.
	$('#main-menu > .menu-item > a.js-accordion-trigger').append('<div class="accordion__trigger-icon js-accordion-trigger-icon"><div class="accordion__plus js-accordion-plus"></div></div>');
	$('.top-nav__secondary-menu').appendTo($('.menu-main_menu-container'));

	modernizer: {
		if (!Modernizr.objectfit) {
			$('.object-fit').each(function () {
				var $container = $(this),
					imgUrl = $container.find('img').prop('src');
				if (imgUrl) {
					$container
						.css('backgroundImage', 'url(' + imgUrl + ')')
						.addClass('not-compatible');
				}
			});
		}
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}

	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}

	menu: {
		// Open submenu on click
		$('.top-nav__site-menu-wrapper .top-nav__hamburger').on('click', function (e) {
			e.preventDefault();
			$('.top-nav__site-menu-wrapper').toggleClass('menu-open');
			$('.button--hamburger').toggleClass('open');
			$('body').toggleClass('no-scroll');
			$('html').toggleClass('no-scroll');

			var parentID = $('#main-menu').find($('.menu-item-has-children.current-page-ancestor'));
			console.log(parentID);

			if ($('body').hasClass('page-child')) {
				setTimeout(
					function () {
						parentID.find('.js-accordion-trigger').next().slideDown('fast');
						parentID.find('.js-accordion-trigger').addClass('open');
					}, 600);


			}
		});


		$('#main-menu > .menu-item > a').on('click', function (e) {
			e.preventDefault();
			// Close only the items in this accordion.
			$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
			$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

			if ($(this).hasClass('open')) {
				$(this).next().slideUp('fast');
				$(this).removeClass('open');
			} else {
				$(this).next().slideDown('fast');
				$(this).addClass('open');
			}


		})
		// Open menu with tabs - accessibility
		$('.top-nav .sub-menu-wrap a').on('focusin', function () {
			$(this).closest('.menu-item-has-children').addClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').addClass('sub-menu-open');

			console.log($(this));
		});
		$('.top-nav .sub-menu-wrap a').on('focusout', function () {
			$(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
		});
		// Close on click outside.
		// $(document).on('click', function(e){
		// 	if ( ! $(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0 ){
		// 		$('.menu-item-has-children').removeClass('sub-menu-open');
		// 		$('.sub-menu-wrap').removeClass('sub-menu-open');
		// 	}
		// });
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top - 60
			}, 500);
		});
	}
	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});
		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');

			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
				// if($('a[href$=".pdf"]').hasClass('button')) {
				$('a[href$=".pdf"]').not('.post-card__resources a[href$=".pdf"]').addClass('button--download button');
				if ($('a[href$=".pdf"]').hasClass('button--primary')) {
					$('a[href$=".pdf"]').addClass('button--download--primary');
				}
				if ($('a[href$=".pdf"]').hasClass('button--secondary')) {
					$('a[href$=".pdf"]').addClass('button--download--secondary');
				}
				// }

			});
		}
	}

	formButton: {
		$('.gform_next_button').each(function () {
			$(this).addClass('button--primary');
		})
		$('.gform_previous_button').each(function () {
			$(this).addClass('button--primary');
		})
		$('.gform_save_link').each(function () {
			$(this).addClass('button--secondary');
		})
		$('.gpnf-add-entry').each(function () {
			$(this).addClass('button button--secondary');
		})
	}

	alternatingButtons: {
		// Check each block for download buttons
		// $('.block').each(function() {
		const backgroundColors = ['lilac', 'secondary', 'turquoise'];

		if ($('.button--download').length) {
			var i = 0;
			console.log('button');
			$('.button--download').each(function () {

				$(this).addClass('button--download--' + backgroundColors[i]);
				i++;
			});
		}
		// })
	}

	l2SubMenu: {
		// open current page menu on load
		const currentMenu = document.querySelector('.l2__menu .current_page_item, .l2__menu .current_page_ancestor');

		if (currentMenu) {
			$(currentMenu).find($('.children')).slideDown('fast');
			$(currentMenu).addClass('sub-menu-slide-down');
		}

		$('.page_item_has_children').on('click', function () {

			if ($(this).hasClass('sub-menu-slide-down')) {
				// $(this).find($('.children')).slideUp('fast');
				// $(this).removeClass('sub-menu-slide-down');
			} else {
				$(this).find($('.children')).slideDown('fast');
				$(this).addClass('sub-menu-slide-down');
			}
		})
	}
	slider: {
		$('.full-width-slider').slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
		});


		/**
		 * Gallery slider with thumbnails navigation.
		 */
		$('.gallery-slider').each(function (index) {
			var status = $(this).find('.gallery-slider__full-nav .pages');
			var slickElement = $(this).find('.gallery-slider__full');
			var slickElementThumb = $(this).find('.gallery-slider__thumb');

			slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				var i = (currentSlide ? currentSlide : 0) + 1;
				status.text(i + ' of ' + slick.slideCount);
			});

			slickElement.slick({
				dots: false,
				arrows: true,
				fade: true,
				asNavFor: $(this).find('.gallery-slider__thumb'),
				appendArrows: $(this).find('.gallery-slider__full-nav'),
			});

			const $thumbSlider = slickElementThumb.slick({
				dots: false,
				arrows: true,
				slidesToShow: 1,
				centerMode: true,				
				focusOnSelect: true,
				asNavFor: $(this).find('.gallery-slider__full'),
			});
			console.log(slickElementThumb.slick("getSlick").slideCount);
			if (slickElementThumb.slick("getSlick").slideCount > 1) {
				const thumbSlider = $thumbSlider[0].slick;
				thumbSlider.options.slidesToShow = 4; // set desired number after init
				if (thumbSlider.slideCount <= thumbSlider.options.slidesToShow) {
					// handle arrows click 
					$thumbSlider.find('.slick-next.slick-arrow').on('click', () => {
						$thumbSlider.find('.slick-slide.slick-current').next().click();
					});
					$thumbSlider.find('.slick-prev.slick-arrow').on('click', () => {
						$thumbSlider.find('.slick-slide.slick-current').prev().click();
					});
				}
			}
		});

		$('.js-home-banner').slick({
			dots: false,
			arrows: false,
			autoplay: false,
			infinite: true,
			fade: true,			
			pauseOnHover: false,
			// speed: 4000,
			autoplaySpeed: 3000,
			// asNavFor: '.js-home-banner-2'
		});
		// $('.js-home-banner').slick('slickPause');
		$('.js-home-banner-2').slick({
			dots: false,
			arrows: false,
			autoplay: true,
			infinite: true,
			fade: true,
			pauseOnHover: false,
			// speed: 2000,
			autoplaySpeed: 4000,
			// asNavFor: '.js-home-banner'
			// slide: '.banner--home__slider--slide'
			// rows: 3,
		});
		$('.js-home-banner-2').slick('slickPause');

		$('.js-home-banner').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			// console.log(nextSlide)
			if (nextSlide > 0) {
				$('.home-logo--green').removeClass('d-none');
				$('.home-logo--white').addClass('d-none');
				$('.top-nav__hamburger').removeClass('white-burger');
				$('.home-skip').addClass('button--primary')
				$('.home-skip').removeClass('button--secondary')
			} else {
				$('.home-logo--green').addClass('d-none');
				$('.home-logo--white').removeClass('d-none');
				$('.top-nav__hamburger').addClass('white-burger');
				$('.home-skip').removeClass('button--primary');
				$('.home-skip').addClass('button--secondary');

			}
		});

		$('.js-featured-slider').slick({
			dots: true,
			arrows: false,
			autoplay: true,
			infinite: true,
			fade: true,
		});
		$('.js-timeline-content').slick({
			infinite: false,
			dots: false,
			arrows: false,
			fade: false,
			autoplay: false,
			autoplaySpeed: 4500,
			asNavFor: '.js-timeline-timeline',
			slidesToShow: 3.5,
			slidesToScroll: 1,
			centerMode: true,
			centerPadding: '10.05% 0 5% ',
			responsive: [
				{
					breakpoint: 1501,
					settings: {
						slidesToShow: 3.38,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 2.5,
						slidesToScroll: 1,
						// centerPadding: '0 5% 0 27%',
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						// centerPadding: '0 5% 0 27%',
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 1.5,
						slidesToScroll: 1,
						// centerPadding: '0 5% 0 27%',
					}
				},
				{
					breakpoint: 575,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						// centerPadding: '0 5% 0 27%',
					}
				},

			]
		});

		// Slides change on scroll 
		// $('.js-timeline-content').on('wheel', (function (e) {
		// 	e.preventDefault();

		// 	if (e.originalEvent.deltaY < 0) {
		// 		$(this).slick('slickNext');
		// 	} else {
		// 		$(this).slick('slickPrev');
		// 	}
		// }));
		$('.js-timeline-timeline').slick({
			infinite: false,
			dots: false,
			arrows: true,
			fade: false,
			autoplay: false,
			autoplaySpeed: 4500,
			asNavFor: '.js-timeline-content',
			slidesToShow: 11,
			slidesToScroll: 1,
			focusOnSelect: true,
			centerMode: false,
			centerPadding: '10.05%',
			responsive: [
				{
					breakpoint: 1201,
					settings: {
						slidesToShow: 9,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 7,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 551,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					}
				},
			]
		});

	}

	$('.js-slider-link').click(function () {
		// $('.js-timeline-content').slickGoTo(1);
		$('.js-timeline-content').slick('slickGoTo', 1);
	});
	$('.js-back-to-start').click(function () {
		// $('.js-timeline-content').slickGoTo(1);
		$('.js-timeline-content').slick('slickGoTo', 0);
	});

	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function (url) {

							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;

						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});


		// INLINE
		$('.magnific-inline-popup').magnificPopup({
			type: 'inline',
			fixedContentPos: false,
			fixedBgPos: true,
			overflowY: 'auto',
			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: 'mfp-fade'
		});


		// GALLERY SINGLE IMAGE
		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});

		// GALLERY IMAGE
		$('.magnific-gallery-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: true
			}
		});
	}

	parallaxImages: {
		function parallaxImages() {
			$('.js-parallax-image').each(function () {
				var trigger = $(this);
				if($(window).innerWidth < 600) {
					var scrub = true;
				} else {
					var scrub = 1.2;
				}
				gsap.to(trigger, {
					xPercent: 0,
					x: () => (-1) * (innerWidth * 1.6),
					// ease: "power3.inOut",
					scrollTrigger: {
						trigger: trigger,
						start: 'top center',
						end: 'bottom top',
						scrub: scrub,
						pin: false,
						
						// markers: true
						//   invalidateOnRefresh: true,
						//   anticipatePin: 1
					},
				});
			});

			// $('.js-parallax-image').slick({
			// 	dots: false,
			// 	arrows: false,
			// 	infinite: false,
			// 	slidesToShow: 3,
			// 	fade: false,
			// 	autoplay: true,
			// 	autoplaySpeed: 5500,
			// 	rows: 1,
			// 	speed: 1200,
			// 	responsive: [
			// 		{
			// 			breakpoint: 600,
			// 			settings: {
			// 				slidesToShow: 1,
			// 			}
			// 		}
			// 	]
			// })
		}
		// document.querySelector('.block--parallax-images').addEventListener('mouseenter', () => {
		window.addEventListener('resize', function () {
			if (window.innerWidth > 650) {
				if($('.block--parallax-images').length) {
					parallaxImages();
				}
			// } else {
			// 	$('.js-parallax-image').slick('unslick');
			}

		})
		if (window.innerWidth > 650) {
		if($('.block--parallax-images').length) {
			parallaxImages();
		}
		}
		// })

	}

	successGrid: {
		function resizeTwitter() {
			var instaPosts = $('.success__grid--col-1');
			var twitterFeed = $('.success__grid--col-3');
			var tweets = twitterFeed.find($('.twitter-feed__tweet'));

			// set max height of twitterFeed to be height of instaPosts always
			if (instaPosts.length > 0) {

				twitterFeed.css('maxHeight', instaPosts.height());

				setTimeout(function () {
					tweets.each(function () {
						console.log($(this))
						$(this).fadeIn();
					});
				}, 300)

			}
		}
		if (window.innerWidth > 1299) {
			resizeTwitter();
		}

		window.addEventListener('resize', function () {
			if (window.innerWidth > 1299) {
				resizeTwitter();
			}
		});

	}
	accordion: {
		if ($('.block--accordion').length) {

			$('.accordion__content--wrapper').css('display', 'none'); // All items closed.

			$('.block--accordion').find('.js-accordion-trigger').on('click', function () {

				// //scroll to opened element
				// $('html, body').animate({
				// 	scrollTop: $(this).offset().top
				// }, 500);
				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

				if ($(this).hasClass('open')) {
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}	


			});
		}
	}

	tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if (tabs) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				tab.addEventListener("click", changeTabs);
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if (tabList) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
						tabs[tabFocus].setAttribute("tabindex", -1);
						if (e.keyCode === 39) {
							tabFocus++;
							// If we're at the end, go to the start
							if (tabFocus >= tabs.length) {
								tabFocus = 0;
							}
							// Move left
						} else if (e.keyCode === 37) {
							tabFocus--;
							// If we're at the start, move to the end
							if (tabFocus < 0) {
								tabFocus = tabs.length - 1;
							}
						}

						tabs[tabFocus].setAttribute("tabindex", 0);
						tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				const target = e.target;
				console.log(target);
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');


				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);


				var contain = parent.closest('.tabs__controls');
				if ($(contain).hasClass('js-tabs-expand')) {
					let otherTab = grandparent.querySelector('.js-tabs-placeholder');

					otherTab
						.querySelectorAll('[aria-selected="true"]')
						.forEach(t => t.setAttribute("aria-selected", false));

					let panel = target.getAttribute("aria-controls");
					otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
				}
				if ($(contain).hasClass('js-tabs-placeholder')) {
					let otherTab = grandparent.querySelector('.js-tabs-expand');

					otherTab
						.querySelectorAll('[aria-selected="true"]')
						.forEach(t => t.setAttribute("aria-selected", false));

					let panel = target.getAttribute("aria-controls");
					otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
				}

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));

				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");

			}

		}
		if (window.location.href.indexOf("#tab-") > -1) {
			var urlTab = window.location.href.substring(window.location.href.indexOf("#tab-"));
			const target = document.querySelector("[href='" + urlTab + "']");
			const parent = target.parentNode;
			const grandparent = parent.closest('.tabs');


			// Remove all current selected tabs
			parent
				.querySelectorAll('[aria-selected="true"]')
				.forEach(t => t.setAttribute("aria-selected", false));

			// Set this tab as selected
			target.setAttribute("aria-selected", true);


			var contain = parent.closest('.tabs__controls');
			if ($(contain).hasClass('js-tabs-expand')) {
				let otherTab = grandparent.querySelector('.js-tabs-placeholder');

				otherTab
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				let panel = target.getAttribute("aria-controls");
				otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
			}
			if ($(contain).hasClass('js-tabs-placeholder')) {
				let otherTab = grandparent.querySelector('.js-tabs-expand');

				otherTab
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				let panel = target.getAttribute("aria-controls");
				otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
			}

			// Hide all tab panels
			grandparent
				.querySelectorAll('[role="tabpanel"]')
				.forEach(p => p.setAttribute("hidden", true));

			// Show the selected panel
			grandparent.parentNode
				.querySelector(`#${target.getAttribute("aria-controls")}`)
				.removeAttribute("hidden");

			$('html, body').animate({
				scrollTop: $(contain).offset().top - 60
			}, 500);
		}

		//links to tab on same page 
		$('a[href^="#tab-"]').each(function() {			
			$(this).on('click', function() {
				var tabLink = $(this).attr('href');
				var tabNumber = tabLink.substring(5)
				console.log(tabNumber);
				// $(this).attr('aria-controls', 'panel-' + tabNumber) ;
				// $(this).attr('role', 'tab') ;
	
				// var urlTab = window.location.href.substring(window.location.href.indexOf("#tab-"));
				const target = document.querySelector("[href='" + tabLink + "']");
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');
	
	
				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));
	
				// Set this tab as selected
				target.setAttribute("aria-selected", true);
	
	
				var contain = parent.closest('.tabs__controls');
				if ($(contain).hasClass('js-tabs-expand')) {
					let otherTab = grandparent.querySelector('.js-tabs-placeholder');
	
					otherTab
						.querySelectorAll('[aria-selected="true"]')
						.forEach(t => t.setAttribute("aria-selected", false));
	
					let panel = target.getAttribute("aria-controls");
					otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
				}
				if ($(contain).hasClass('js-tabs-placeholder')) {
					let otherTab = grandparent.querySelector('.js-tabs-expand');
	
					otherTab
						.querySelectorAll('[aria-selected="true"]')
						.forEach(t => t.setAttribute("aria-selected", false));
	
					let panel = target.getAttribute("aria-controls");
					otherTab.querySelector('[aria-controls="' + panel + '"]').setAttribute("aria-selected", true);
				}
	
				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));
	
				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");
	
				$('html, body').animate({
					scrollTop: $(contain).offset().top - 60
				}, 500);
	
			})
			
			
		})
	}
	expandTabs: {

		var expandTabs = $('.js-tabs-expand');
		var placeholder = $('.js-tabs-placeholder');
		var container = $('.tabs__controls--container');
		var content = $('.tabs__content');

		if (expandTabs.length) {
			// move content up by half height of controls
			if (window.innerWidth > 600) {
				content.css('margin-top', (container.height() * 0.5) * -1);
			} else {
				content.css('margin-top', '50px');
			}

			var initialOffset = placeholder.offset().top; //gets offset of header
			var height = placeholder.outerHeight(); //gets height of header

			$(window).scroll(function () {
				if ($(window).scrollTop() > (initialOffset + height)) {
					expandTabs.addClass('show-nav');
					container.addClass('widen');
				}
				else {
					container.removeClass('widen');
					expandTabs.removeClass('show-nav');
					// setTimeout( function() {
					// 	expandTabs.removeClass('scroll-down');											
					// }, 600);
				}
			});
		}


	}
	scrollSteps: {
		function scrollSteps() {
			let stepPage = document.querySelector('.steps');
			var offsetTop = "";
			if (stepPage) {
				console.log($('[id^=step-tab-1-'));
				$('[id^=step-tab-1-').each(function () {
					if ($(this).offset().top) {
						// Define offset Top based on tab that has been selected
						offsetTop = $(this).offset().top;
					}


				});
				// const offsetTop = $('[id=step-tab-1-1]').offset().top; 
				$(window).scroll(function () {
					let currentButton = document.querySelector('[aria-selected="true"');
					let currentTab = document.getElementById(currentButton.getAttribute('aria-controls'));
					var scrollTop = $(document).scrollTop();
					var steps = $(currentTab).find('.step__text');

					for (var i = 0; i < steps.length; i++) {
						if (scrollTop > $(steps[i]).offset().top - 300 && scrollTop < $(steps[i]).offset().top + $(steps[i]).height() - 300) {
							$('.steps[href="#' + $(steps[i]).attr('id') + '"]').addClass('active');
						} else if (scrollTop < offsetTop) {
							// console.log('hello');
							$('.steps[href="#' + $('[id^=step-tab-1-]').attr('id') + '"]').addClass('active');
						} else {
							$('.steps[href="#' + $(steps[i]).attr('id') + '"]').removeClass('active');
						}
					}
				});
			}
		}

		scrollSteps();

		$(window).on('resize', function() {
			scrollSteps();
		})


	}
	singleImageFixed: {
		// stop the image on single posts scrolling past end of story
		// var backToButton = document.getElementById('stop-scroll');
		var backToButton = $('#stop-scroll');
		if ($('body').is('.single-post, .single-headsblog, .single-studentsuccess') && window.innerWidth > 991) {
			var maxScroll = (backToButton.offset().top) - window.innerHeight;

			var scrollingImage = $('#scrolling-image');

			let banner = document.querySelector('.banner--single');
			let scrollPos = banner.offsetHeight - window.innerHeight;
			window.addEventListener('scroll', () => {
				var scrolled = scrollingImage.offset();
				if ((window.innerWidth - window.innerHeight) > 1.88) {
					// console.log($(document).scrollTop());
					if ($(document).scrollTop() >= (maxScroll - (scrollingImage.height()))) {
						scrollingImage.parent().css('position', 'absolute');
						var topValue = maxScroll + 210;
						scrollingImage.parent().css('top', (maxScroll - (scrollingImage.height())));

					} else {
						scrollingImage.parent().css('position', 'fixed');
						scrollingImage.parent().css('top', '210px');
					}
				} else {
					
					// maxScroll scrollingImage.height()
					if ($(document).scrollTop() >= (maxScroll - (scrollingImage.height()))) {
						scrollingImage.parent().css('position', 'absolute');
						scrollingImage.parent().css('top', maxScroll - (scrollingImage.height()));

					} else {
						scrollingImage.parent().css('position', 'fixed');
						scrollingImage.parent().css('top', '230px');
					}
				}
			})
		}
	}
	singlePaddingAdd: {
		if (window.innerWidth < 769) {
			$('#change-padding').addClass('container-padding');
		} else {
			$('#change-padding').removeClass('container-padding');
		}
	}
	keywords: {
		var keywords = document.querySelectorAll('.keyword');
		var images = document.querySelectorAll('.hover-image');

		for (let i = 0; i < keywords.length; i++) {
			(function (index) {
				keywords[index].addEventListener("mouseenter", function () {
					$(images[index]).addClass('hovered');
				})
				keywords[index].addEventListener("mouseleave", function () {
					$(images[index]).removeClass('hovered');
				})
			})(i);
		}

	}
	threeVideos: {
		// Only play video on hover for computers
		if (window.innerWidth > 991) {
			$(".js-three-videos").each(function () {
				$(this).hover(hoverVideo, hideVideo);
				function hoverVideo(e) {
					$(this).siblings($('video')).trigger('play');

				}
				function hideVideo(e) {
					$(this).siblings($('video')).trigger('pause');
				}
			});
		}
	}

	wrapResponsiveIframe: {
		$('.main iframe[src*="vimeo.com"]').wrap('<div class="iframe-wrapper"></div>');
		$('.main iframe[src*="youtube.com"]').wrap('<div class="iframe-wrapper"></div>');
		$('.main iframe[src*="artsteps.com"]').wrap('<div class="iframe-wrapper"></div>');
	}
	loadingAnimations: {

		// gsap.from($('.block--stat-counter'), {
		// 	textContent: 0,
		// 	duration: 4,
		// 	ease: "power1.in",
		// 	snap: { textContent: 1 },			
		// 	scrollTrigger: {
		// 		trigger: $(this),
		// 		markers: true,
		// 		start: "top",
		// 		onUpdate: () => statCount,
		// 	}
		//   });

		//homepage loader
		if ($('.js-home-banner').length) {
			var tl = gsap.timeline({
				// ease: "power3.inOut",
				onStart: function () {
					$('body').addClass('no-scroll');
				},
				onComplete: function () {
					$('.js-home-banner-2').slick('slickPlay');
					
					$('body').removeClass('no-scroll');
				}
			});

			tl
				.to(".js-initial", {
					autoAlpha: 0,
					zIndex: -1,
				}, "<")
				.from(".js-banner-home-width", {
					autoAlpha: 1,
					zIndex: 5,
					width: '100%',
					duration: 0.8,
					ease: CustomEase.create("custom", "0.76, 0.00, 0.24, 1.00"),
					zIndex: 1,
				}, ">=+1.5")
				.from(".top-nav", {
					zIndex: -1,
					autoAlpha: 0
				}, "<")
				.from(".slick-active .js-banner-home-reveal .first-image img", {
					autoAlpha: 1,
					xPercent: -101,
					duration: 0.8,
					ease: CustomEase.create("custom", "0.76, 0.00, 0.24, 1.00"),
					zIndex: 1,
					onComplete: placeholderImageSet(),
				}, "<")
				.from(".js-banner-home-preloader", {
					opacity: 1,
					duration:0.4,
					visibility: "visible",
					ease: "linear",
					zIndex: 1,
				},  "<-=0.4")
				.from(".js-banner-home-logo", {
					opacity: 0,
					// x: "-150",
					duration: 0.167,
					ease: "linear",
				},  "-=50%")
				// .from(".js-banner-home-menu", {
				// 	// autoAlpha: 1,
				// 	// xPercent: "100",					
				// 	// opacity: 0,
				// 	duration: 0.667,
				// 	ease: CustomEase.create("custom", "0.26, 1.33, 0.48, 1.00"),
				// },  "<")
				.from(".js-banner-home-title-left", {
					// autoAlpha: 1,
					x: "-60",
					opacity: 0,
					duration: 0.667,
					ease: CustomEase.create("custom", "0.26, 1.33, 0.48, 1.00"),
				},  "<-=0.4083")
				.from(".js-banner-home-title-right", {
					// autoAlpha: 1,
					x: "60",
					opacity: 0,
					duration: 0.667,
					ease: CustomEase.create("custom", "0.26, 1.33, 0.48, 1.00"),
				},  "<")
				.from(".js-banner-home-visible", {
					// autoAlpha: 1,
					duration: 0.667,
					opacity: 0,					
					ease: CustomEase.create("custom", "0.26, 1.00, 0.48, 1.00"),
				}, ">+=0.875")
				.from(".slick-active .js-banner-home-zoom img", {
					// autoAlpha: 1,
					opacity: 0,
					scale: 1.3,
					duration: 0.667,
					ease: CustomEase.create("custom", "0.26, 1.00, 0.48, 1.00"),

				}, "<")
				.from(".js-banner-home-up", {
					// autoAlpha: 1,
					y: 70,
					duration: 0.667,
					opacity: 0,
					ease:  'linear'

				}, "<-=0.1")
				.from(".js-banner-home-opacity", {
					autoAlpha: 1,
					opacity: 1,
					duration: 0.1,
					ease: 'linear',
				},  "<+=0.1")

				

				

			// set size of placeholder
			function placeholderImageSet() {
				$('.placeholder-image').each(function() {
					var thisIndex = $(this).attr('data-placeholder-index');
					var slideOne = $('[data-slick-index="' + thisIndex + '"]');
					slideOne.find('.js-banner-home-reveal').append($(this));

					var phWidth = $('this').parent().width();
					$(this).width(phWidth);
				})
			}

			$('.js-home-banner-2').on('beforeChange', function (event, slick, currentSlide, nextSlide) {				
				var tlSlick = gsap.timeline({
					ease: "power3.inOut",
					
					
				});
				var nextContainer = $($(this).find('[data-slick-index=' + nextSlide + ']'));
				var nextImage = $('[data-slick-index=' + nextSlide + ']').find('.js-banner-home-reveal .first-image img');
				var nextZoom = $('[data-slick-index=' + nextSlide + ']').find('.js-banner-home-zoom img');
				var currentTitle = $('[data-slick-index=' + currentSlide + ']').find('.js-banner-home-title-right');
				var nextTitle = $('[data-slick-index=' + nextSlide + ']').find('.js-banner-home-title-right');

				setTimeout(function() {
					$('.js-home-banner').slick('slickGoTo', nextSlide);
				}, 1500);
				tlSlick
					// opacity of foreground image set to 1 before any slide change
					.to(nextContainer, {
						opacity: 1,
						duration: 1.5,
					}, '<')
					.from(nextImage, {
						autoAlpha: 1,						
						xPercent: -100,
						duration: 0.833,						
						ease: CustomEase.create("custom", "0.76, 0.00, 0.24, 1.00"),						
						zIndex: 1,
					} , '>')
					.from(nextZoom, {
						autoAlpha: 1,
						opacity: 0,
						scale: 1.3,
						duration: 1.1,
						ease: CustomEase.create("custom", "0.26, 0.50, 0.48, 1.00"),
					}, '<')
					.fromTo(currentTitle, {
						autoAlpha: 1,						
						xPercent: 0,
						duration: 0.567,						
						ease: CustomEase.create("custom", "0.17, 0.00, 0.83, 1.00"),						
						zIndex: 1,
					}, {
						xPercent: 10,
					},'<')
					.fromTo(nextTitle, {
						autoAlpha: 1,						
						xPercent: 10,
						duration: 0.567,						
						ease: CustomEase.create("custom", "0.17, 0.00, 0.83, 1.00"),						
						zIndex: 1,
					}, {
						xPercent:0,
					}, '<+=0.1')

			});			
			
		}


		// twk animate on scroll
		function AnimateOnScroll() {
			const elements = document.querySelectorAll('[twk-aos]');

			if (!elements) return;

			[...elements].forEach(element => {
				const twkAosTrigger = element.getAttribute('twk-aos-trigger') ? element.getAttribute('twk-aos-trigger') : 'bottom-=150px';
				const twkAosMarker = element.hasAttribute('twk-aos-marker');

				ScrollTrigger.create({
					trigger: element,
					start: `top ${twkAosTrigger}`,
					end: 'bottom bottom',
					markers: twkAosMarker,
					onEnter: () => element.classList.add('twk-aos-animate'),
				});
			})
		}

		AnimateOnScroll();

		$(document).on("sf:ajaxfinish", ".searchandfilter", function(){
			console.log("ajax complete");
			AnimateOnScroll();
		});

		// stat counter
		$('.block--stat-counter').each(function () {
			let trigger = $(this)

			ScrollTrigger.create({
				trigger: trigger,
				start: "top bottom",
				once: true,
				onEnter: function () {
					$('.js-value').each(function () {
						$(this).prop('Counter', 0).animate({
							Counter: $(this).text()
						}, {
							duration: 4000,
							easing: 'swing',
							step: function (now, tween) {
								if (parseInt(tween.end) == parseFloat(tween.end)) {
									$(this).text(Math.ceil(now));
								} else {
									$(this).text(now.toFixed(1));
								}
							}
						});
					});

				},
			});

		});

		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');

			}

		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});


